import { Divider } from "antd"
import Logo from "../resource/logo.png"

const ErrorPage: React.FC = () => {

    return(
        <div style={{height: window.innerHeight}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: window.innerHeight * 0.3}}>
                <img src={Logo} />
            </div>
            <br/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, fontFamily: 'Arial'}}>
                哎呀，出错了!
            </div>
            <Divider/>
            <p style={{color: 'gray', fontSize: 12,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>请稍后重试</p>
        </div>
    )
}

export default ErrorPage