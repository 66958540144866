import React, { useLayoutEffect, useState } from "react";
import Axios from "axios";
import { List, Modal } from "antd";
import { CityListURL, HomeSearchURL } from "../../Constants";
import Masonry from "react-responsive-masonry";
import MarkerCard from "../../components/card/MarkerCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Pointer from "../../components/pointer/Pointer";
import SearchInput from "../../components/search/Search";
import { getAxiosInstance } from "../../components/login/Auth";
import MarkerPC from "../marker/MarkerPC";
import { useSearchParams } from "react-router-dom";

interface MarkerVO{
    id: string,
    title: string,
    faceImage: string,
    imageWidth: number,
    imageHeight: number,
    userId: number,
    username: string,
    userImage: string
}

interface IState{
    citys: string[],
    markers: MarkerVO[],
    showModal: boolean,
    count: number
}

interface IProp{
    url?: string,
    needHeader?: boolean
}


var keyword: string | undefined = undefined
var city: string = "推荐"
var page: number = 0
var hasMord: boolean = true

const MarkerList: React.FC<IProp> = (props) => {
    
    const [searchParams, setSearchParams] = useSearchParams()
    const [markerId, setMarkerId] = useState<string>()
    const [citys, setCitys] = useState(['推荐'])
    const [markers, setMarkers] = useState<MarkerVO[]>([])
    const [count, setCount] = useState(5)
    const [showDetailModal, setShowDetailModal] = useState(false)
    const [initFlag, setInitFlag] = useState(false)

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        const id = searchParams.get("id")
        if(id){
            setMarkerId(id)
            setShowDetailModal(true)
        }
        if(props.needHeader === undefined || props.needHeader){
            queryCityList()
        }
        search(true)
        caculateHeight()
        //calculateCount()
        window.addEventListener('resize',() => {calculateCount(); caculateHeight()})
    })


    /**
     * 计算列数
     */
    function calculateCount(){
        if(!document.getElementById('marker_box')){
            return
        }
    
        setCount(Math.floor(document.getElementById('marker_box')!.offsetWidth / 250))
         
    }

    /**
     * 计算高度
     */
    function caculateHeight(){
        var root: HTMLElement | null = document.getElementById('marker_list_box')
        var header: HTMLElement | null = document.getElementById('marker_list_header')

        if(root !== undefined && root !== null && root.style !== undefined && root.style !== null && header !== undefined && header !== null){
            root.style.height =  document.documentElement.clientHeight - header!.offsetHeight - 20 + 'px'
        }
    }

    /**
     * 搜索
     */
    function search(clear: boolean): void{
        if(clear === true){
            page = 0
        }
        let currentPage = page
        page = page + 1
        const url: string = (props.url ? props.url : HomeSearchURL) + "?page=" + currentPage + (keyword !== undefined ? "&keyword=" + keyword : "") + (city !== "推荐" ? "&city=" + encodeURIComponent(city) : "")
        getAxiosInstance().get(url)
        .then(
            response => {
                if(response.data !== undefined && response.data.data !== undefined && response.data.data != null){
                    if(response.data.data.totalPages === page || response.data.data === undefined){
                        hasMord = false
                    }else{
                        hasMord = true
                    }
                    setMarkers(clear ? response.data.data.content : markers.concat(response.data.data.content))
                }
            }
        )
    }

    /**
     * 查询城市列表
     */
    function queryCityList(): void {
        Axios.get(CityListURL)
        .then(
            response => {
                let data = response.data.data
                setCitys(citys.concat(data))
            }
        )
    }
    

    return(
        <div id="marker_box" style={{height: '100%',overflow: 'hidden'}}>
            {
                (props.needHeader === undefined || props.needHeader) ?
                    <div id="marker_list_header">
                        <div style={{ textAlign: 'center'}}>
                                    <SearchInput placeholder="山海不见你，我便带着山海向你走来~" submitCallback={value => {
                                        if(value === ""){
                                            keyword = undefined
                                        }else{
                                            keyword = value
                                        }
                                        search(true)
                                    }}/>
                                </div>
                        <div style={{marginTop: "40px"}}>
                            <List key={""} dataSource={citys} renderItem={item => (<Pointer name={item} selectFlag = {item === city} onClick={(name:string)  => {
                                city = name
                                search(true)
                            }}/>)}/>
                        </div>
                    </div>
                    :
                    <div/>
            }


            {
                markerId ? <Modal  open={showDetailModal} width={window.innerWidth * 0.6} footer={false} maskClosable={true} onCancel={e => setShowDetailModal(false)}>
                    <MarkerPC id={markerId}/>
                </Modal> : <></>
            }
            
            <div id="marker_list_box" style={{marginTop: '20px',overflowY: 'auto'}}>
                <InfiniteScroll style={{overflowX: 'scroll',scrollbarWidth: 'none', whiteSpace: 'nowrap'}} next={() => search(false)} hasMore={hasMord} dataLength={markers.length} loader endMessage={<div style={{textAlign: "center", color: 'gray'}}>没有啦~</div>}>
                    
                        <Masonry columnsCount={count}> 
                            {markers.map(item => <MarkerCard id={item.id} title={item.title} iamge={item.faceImage} key={item.id}
                            width={item.imageWidth} height={item.imageHeight} viewWidth={250} userImage={item.userImage} username={item.username} onClick={key =>{
                                setMarkerId(key) 
                                setShowDetailModal(true)
                            } }/>)}
                        </Masonry>
                    
                </InfiniteScroll>
            </div>
        </div>
    )

}

export default MarkerList