import {useLayoutEffect, useState } from "react";
import { PlanMapPublicURL } from "../../Constants";
import Axios from "axios";
import MarkerNode from "../../components/planmap/MarkerNode";
import StayNode from "../../components/planmap/StayNode";
import FoodNode from "../../components/planmap/FoodNode";
import RouteNode from "../../components/planmap/RouteNode";
import LogoNavitaion from "../../components/common/LogoNavigation";
import { useParams } from "react-router-dom";
import ErrorPage from "../../support/ErrorPage";
import StartNode from "../../components/planmap/StartNode";

import './planmap.css'
import { Button, Flex, Input, message } from 'antd';
import type { GetProp } from 'antd';
import type { OTPProps } from 'antd/es/input/OTP';

import BG from '../../resource/planmap_pwd_bg.jpg'
import BG_PC from '../../resource/planmap_pwd_bg_pc.jpg'
import { isPC } from "../../utils/SystempUtils";

interface PlanMapModel{
    id: number,
    userId: number,
    username: string,
    userImage: string,
    markers: PlanMapMarker[]
}

export interface PlanMapMarker{
    id: number,
    type: string,   //节点类型
    parentId: number,
    title: string,
    jsonData: string,
    address: string,
    markerId: string,
    cost: number,//游玩费用
    estimatedCost: number,//路费
    arrivalTime: string,//到达时间
    remark: string,
    trafficType: string,//出行方式
    trafficDuration: number, //交通耗时(分钟)
    trafficDepartureDate: string, //出发时间
    estimatedTime: number,//预计交通时常
    distance: number,//距离,
    sort: number ,//排序号,
    finishFlag: boolean,//是否已经完成打卡
    relationImage: string,//关联笔记的封面图片
    relationId: string //关联的笔记

}

const PlanMap: React.FC = () => {

    const [initFlag, setInitFlag] = useState(false)
    const [loadFlag, setLoadFlag] = useState(false)
    const [planMap, setPlanMap] = useState<PlanMapModel>()
    const {id,pwd} = useParams()
    const [error, setError] = useState(false)
    const [passwordFlag, setPasswordFlag] = useState(false)
    const [password, setPassword] = useState<String | undefined>()
    const [btnDisableFlag, setBtnDisableFlag] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        setPassword(pwd)
        loadData()
    })

    function loadData(){
        setBtnDisableFlag(true)
        Axios.get(password !== undefined ? `${PlanMapPublicURL}?planMapId=${id}&password=${password}` : `${PlanMapPublicURL}?planMapId=${id}`)
        .then(
            response => {
                if(password?.length === 5){
                    setBtnDisableFlag(false)
                }
                setLoadFlag(true)
                if(response.data && response.data.success && response.data.code == 'ok'){
                    let data = response.data.data
                    setPasswordFlag(false)
                    setPlanMap({
                        id: data.id,
                        userId: data.userId,
                        username: data.username,
                        userImage: data.userImage,
                        markers: data.markers === undefined ? [] : data.markers as PlanMapMarker[]
                    })
                }else if(response.data.message === '请输入查看密码' || response.data.message === '查看密码错误'){
                    if(response.data.message === '查看密码错误'){
                        messageApi.open({
                            type: 'error',
                            content: '密码不正确！',
                          });
                    }
                    setPasswordFlag(true)
                }else{
                    setError(true)
                }
            }
        )
    }

    const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
        setPassword(text)
        setBtnDisableFlag(false)
      };
    
      const sharedProps: OTPProps = {
        onChange,
      };

    /**
     * 输入密码页面
     * @returns 
     */
    function renderPassword(): JSX.Element{
        return(
            <div className="password-box" style={{height: window.innerHeight, backgroundImage: isPC() ? `url(${BG_PC})` : `url(${BG})`}}>
                
                <div className="password-card">
                    <h1>Password</h1>
                    <Flex gap="middle" align="center" justify="center" vertical>
                        <Input.OTP length={5} size="large" formatter={(str) => str.toUpperCase()} {...sharedProps} />
                        <p>请向发布者询问密码哦！</p>
                        <Button type="primary" disabled = {btnDisableFlag} onClick={() => loadData()}>确认</Button>
                    </Flex>
                </div>
            </div>
        )
    }

    function renderError(): JSX.Element{
        return(
            <ErrorPage/>
        )
    }
   
    function renderMain(): JSX.Element{
        return(
            planMap?.markers.length === 0 ? <div /> :
            <div style={{width: window.innerWidth - 20,marginTop: '100px',paddingRight: '20px',overflowX: 'hidden'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <StartNode />
                </div>
                {planMap?.markers.map(item => 
                    <div style={{display: 'flex', flexDirection: 'column'}} key={item.id}> 
                        {item.type === "Marker" ? <MarkerNode marker = {item} /> : item.type === "Stay" ? <StayNode marker={item}/> : item.type === "Food" ? <FoodNode marker={item}/>  : <></>}
                        {item.sort > 1 ? <RouteNode marker={item}/> : <></>}
                    </div>)
                }

                <LogoNavitaion />
            </div>
        )
    }

    return(
        <div >
            {contextHolder}
            {
                error ? renderError() : passwordFlag ? renderPassword() : loadFlag ? renderMain() : <div/>
            }
        </div>
    )
}

export default PlanMap