import React from 'react';
import {HashRouter, useRoutes} from 'react-router-dom'
import './App.css';
import { RouteList } from './support/RouteList';

function BeforeRouterEnter(){
  return useRoutes(RouteList)
}

const App: React.FC = () =>  {

  return (
    <HashRouter>
        <BeforeRouterEnter/>
    </HashRouter>
   
  );
}

export default App;
