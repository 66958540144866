import { Navigate, RouteObject } from "react-router-dom";
import Home from "../page/home/Home";
import PlanMap from "../page/planmap/PlanMap";
import Marker from "../page/marker/Marker";
import NotFoundPage from "./NotFoundPage";
import MarkerList from "../page/home/MarkerList";
import Selected from "../page/home/selected/Selected";
import MessageBoard from "../page/event/MessageBoard";
import TrackRecord from "../page/event/TrackRecord";

export const RouteList: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to={'/home'}/>
    },
    {
        path: '/home',
        element: <Home/>,
        children: [
            {
                path: '/home/find',
                element: <MarkerList />
            },
            {
                path: '/home/selected',
                element: <Selected />
            }
        ]
    },
    {
        path: '/plan_map/:id',
        element: <PlanMap/>
    },
    {
        path: '/marker/:id',
        element: <Marker/>
    },
    {
        path: '/event/:id',
        element: <MessageBoard/>
    },
    {
        path: '/track/:id/:pwd',
        element: <TrackRecord/>
    },
    {
        element: <NotFoundPage/>
    }
]