import Axios from "axios"
import AMapLoader from '@amap/amap-jsapi-loader';
import { useLayoutEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { GET_TRACK_RECORD } from "../../Constants"
import { message } from "antd"
import ErrorPage from "../../support/ErrorPage"


const TrackRecord: React.FC = () => {
    const [initFlag, setInitFlag] = useState(false)
    const {id, pwd} = useParams()
    const [error, setError] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        if(id === undefined || pwd === undefined){
            setError(true)
            return
        }
        loadData()
    })

    function loadData(){
        AMapLoader.load({
            key:"115c1d482a06b01eab866da6ec1e0ca7",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version:"2.0",
            plugins:['AMap.ToolBar','AMap.Driving'],
            AMapUI:{
                version:"1.1",
                plugins:[],

            },
            Loca:{
                version:"2.0.0"
            },
        }).then((AMap)=>{
            let map = new AMap.Map("container",{ //设置地图容器id
                viewMode:"3D",         //是否为3D地图模式
                zoom:17,                //初始化地图级别
                center:[105.602725,37.076636], //初始化地图中心点位置
            });
            Axios.get(`${GET_TRACK_RECORD}?eventId=${id}&pwd=${pwd}`)
            .then(response => {
                if(response.data && response.data.success && response.data.code == 'ok'){
                    let data:{longitude: never, latitude: never}[] = response.data.data as {longitude: never, latitude: never}[]
                    if(data === undefined || data.length === 0){
                        return 
                    }
                    
                    var lineArr:[number,number][] = []
                    data.forEach(x => {
                        lineArr.push([x.longitude, x.latitude])
                    })
                    if(lineArr.length == 0){
                        return
                    }
                    let marker = new AMap.Marker({
                        map: map,
                        position: lineArr[0],
                        icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
                        offset: new AMap.Pixel(-13, -26),
                    });
                    // 绘制轨迹
                    var polyline = new AMap.Polyline({
                        map: map,
                        path: lineArr,
                        showDir:true,
                        strokeColor: "#28F",  //线颜色
                        // strokeOpacity: 1,     //线透明度
                        strokeWeight: 6,      //线宽
                        // strokeStyle: "solid"  //线样式
                    });

                    var passedPolyline = new AMap.Polyline({
                        map: map,
                        strokeColor: "#AF5",  //线颜色
                        strokeWeight: 6,      //线宽
                    });

                    marker.on('moving', function (e: { passedPath: any; target: { getPosition: () => any; }; }) {
                        passedPolyline.setPath(e.passedPath);
                        map.setCenter(e.target.getPosition(),true)
                    });
            
                    map.setFitView();
            
                    //window.startAnimation = function startAnimation () {
                        marker.moveAlong(lineArr, {
                            // 每一段的时长
                            duration: 500,//可根据实际采集时间间隔设置
                            // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
                            autoRotation: true,
                        });
                   /* };
            
                    window.pauseAnimation = function () {
                        marker.pauseMove();
                    };
            
                    window.resumeAnimation = function () {
                        marker.resumeMove();
                    };
            
                    window.stopAnimation = function () {
                        marker.stopMove();
                    };*/
                }else {
                    if(response.data !== undefined && response.data.message != undefined){}
                        messageApi.open({
                            type: 'error',
                            content: response.data.message,
                        });
                    }
                }
            )
        }).catch(e=>{
            console.log(e);
        })
        
    }

    function renderMain(): JSX.Element{
        return(
            <div>
                <div id="container" style={{margin: '0px', padding: '0px', width: '100%',height: window.innerHeight}}/> 
                
            </div>

        )
    }

    return(
        <div>
            {
                error ? <ErrorPage /> : renderMain()
            }
        </div>
    )
}

export default TrackRecord