import { PlanMapMarker } from "../../page/planmap/PlanMap"
import React from "react";
import {smartDateDesc } from "../../utils/DateUtils";
import AirICON from "../../resource/air_icon.svg"
import CarICON from "../../resource/car_icon.svg"
import WalkICON from "../../resource/walk_icon.svg"
import RideICON from "../../resource/ride_icon.svg"
import TrainICON from "../../resource/train_icon.svg"
import MetroICON from "../../resource/metro_icon.svg"

import './Node.css'

interface IProp{
    marker: PlanMapMarker
}
const RouteNode: React.FC<IProp> = (props) => {

    function calculateHour(minute: number): string{
        let hour = Math.floor(minute / 60)
        let minu = minute % 60
        if(hour > 0){
            return `${hour}小时${minu}分钟`
        }else{
            return `${minu}分钟`
        }
    }

    return(
        <div style={{display: "flex",height: '150px', justifyContent: 'center', alignItems: 'center'}}>
            <span className="line"/>

            <div style={{marginLeft: "15px",height: '50px',width: '100%'}}>
                <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{width: "20px",height: "20px"}}>
                        <img style={{height: "18px"}} src={props.marker.trafficType === "Car" ? CarICON : props.marker.trafficType === "Air" ? AirICON : props.marker.trafficType === "Train" ? TrainICON : props.marker.trafficType === "Metro" ? MetroICON : props.marker.trafficType === "Walk" ? WalkICON : props.marker.trafficType === "Ride" ? RideICON : undefined} alt="..."/>
                    </span>
                    <span style={{marginLeft: '10px',fontSize: '12px',color: 'gray'}}>
                        {`需要${calculateHour(props.marker.trafficDuration)}`}
                    </span>
                </div>
                <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{fontSize: '12px', color: 'gray'}}>{`出发时间：${smartDateDesc(new Date(props.marker.trafficDepartureDate))}`}</div>
                </div>
            </div>
        </div>
    )
}

export default RouteNode