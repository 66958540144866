import React, { useEffect, useLayoutEffect, useState } from "react";
import AMapLoader from '@amap/amap-jsapi-loader';
import Axios from "axios";
import { BeautyMarkerURL } from "../../../Constants";

import './Selected.css'

interface MarkerVO{
    id: number,
    title: string,
    url: string,
    longitude: number,
    latitude: number
}

const Selected: React.FC = () => {
    const [initFlag, setInitFlag] = useState(false)
    
    function caculateHeight(){
        if(document.getElementById("container") !== undefined && document.getElementById("container") !== null){
            document.getElementById('container')!.style.height =  document.documentElement.clientHeight + 'px'
        }
    }


    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.setAttribute("src", 'support.js');
        if(head){
          head.appendChild(script);
    
          return () => {
            head.removeChild(script);
          };
        }
      }, ['support.js']);

    useLayoutEffect(() =>{
        if(!initFlag){
            setInitFlag(true)
            window.addEventListener("resize",caculateHeight)
            AMapLoader.load({
                key:"115c1d482a06b01eab866da6ec1e0ca7",                     // 申请好的Web端开发者Key，首次调用 load 时必填
                version:"2.0",
                plugins:['AMap.ToolBar','AMap.Driving'],
                AMapUI:{
                    version:"1.1",
                    plugins:[],
    
                },
                Loca:{
                    version:"2.0.0"
                },
            }).then((AMap)=>{
                let map = new AMap.Map("container",{ //设置地图容器id
                    viewMode:"3D",         //是否为3D地图模式
                    zoom:7,                //初始化地图级别
                    center:[105.602725,37.076636], //初始化地图中心点位置
                });
                Axios.get(`${BeautyMarkerURL}?level=${1}`)
                    .then(response =>{
                        var data: MarkerVO[] = []
                        if(response.data && response.data.success && response.data.code === 'ok'){
                            data = data.concat(response.data.data)
                        }
                        
                        var result:any = []
                        data.forEach(x => {
                            var position = new AMap.LngLat(x.longitude, x.latitude);
                        
                             // 点标记显示内容，HTML要素字符串
                                var markerContent = `' +
                                '<div class="custom-content-marker">' +
                                '   <img src="${x.url}" onclick="jumpMarker(${x.id})">' +
                                '</div>`;
    
                            var marker = new AMap.Marker({
                                position: position,
                                // 将 html 传给 content
                                content: markerContent,
                                // 以 icon 的 [center bottom] 为原点
                                //offset: new AMap.Pixel(-13, -30)
                            });
    
                            // 将 markers 添加到地图
                           // map.add(marker);
                            result.push(marker)
                            
                        })
                        map.add(result)
                    })
            }).catch(e=>{
                console.log(e);
            })
        }
        setInitFlag(true)
    })


    return(
        <div id="container" style={{margin: '0px', padding: '0px', width: '100%',height: window.innerHeight}}/> 
    )
}

export default Selected