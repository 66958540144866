import React, { useLayoutEffect } from "react";

import {loadUserInfo } from "../../components/login/Auth";
import PC from "./pc/PC";
import Mobile from "./Mobile";
import { useParams } from "react-router-dom";
import { isPC } from "../../utils/SystempUtils";


const Home: React.FC = () => {

    useLayoutEffect(() => {
        loadUserInfo()
    })



    return(
        <div>
            {isPC() ? <PC /> : <Mobile />}
        </div>
    )
}

export default Home