/**
 * npm i --save-dev @types/base-64   安装 base46 的包
 */
import Axios from "axios";
import Base64  from 'base-64';

/**
 * 登录的后端地址 POST
 */
const LOGIN_URL: string = "/auth/login"

/**
 * 获取授权吗的后端地址 GET
 */
const AUTHORIZATE_CODE_URL: string = "/auth/oauth2/authorize"

/**
 * 获取 Token 的后端地址 POST
 */
const TOKEN_URL: string = "/auth/oauth2/token"

/**
 * 根据 Token 查询用户信息
 */
const SELF_TOKEN: string = "/auth/oauth2/introspect"

const DEFAULT_CLIENT_ID: string = "default"
const DEFAULT_CLIENT_SECRET: string = "secret"

/**
 * localStorage 存储 token 的 key
 */
const LOCAL_STORAGE_TOKEN_KEY: string = "token"
/**
 * localStorage 存储用户信息的 key
 */
const LOCAL_STORAGE_USER_KEY: string = "user";

const axiosInstance = Axios.create({
    withCredentials: true
  })
  
const publicAxiosInstance = Axios.create()

export function getAxiosInstance(){
    
    publicAxiosInstance.interceptors.request.use(config => {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
        if(token){
            const tokenJSON = JSON.parse(token as string)
            config.headers.JwtToken = `Bearer ${tokenJSON.token}`
        }
        return config
      })
      publicAxiosInstance.interceptors.response.use(res => {
        if(res.status === 401){
            logout()
        }
        return res
      })
      return publicAxiosInstance
}

interface Token{
    token: string,
    refreshToken: string,
    expires_in: string
}

interface UserInfo{
    userId: number,
    username: string,
    userImage: string,
    userVipFlag: boolean
}

/**
 * 通过账号密码登录
 * @param username  用户名
 * @param password  密码
 */
export function login(username: string, password: string,successCallback?: () => void){
    if(username === undefined || username === '' || password === undefined || password === ''){
        return;
    }
    axiosInstance.defaults.withCredentials = true
    axiosInstance.post(LOGIN_URL+`?username=${username}&password=${password}`)
    .then(response => {
        if(response.status === 200 && response.data === 'ok'){
            axiosInstance.get(AUTHORIZATE_CODE_URL + `?client_id=${DEFAULT_CLIENT_ID}&response_type=code`,{
                maxRedirects: 0
            })
            .then(authorizateRes => {
                const code = authorizateRes.data
                const authToken = Base64.encode(DEFAULT_CLIENT_ID + ":" + DEFAULT_CLIENT_SECRET)
                axiosInstance.post(TOKEN_URL + `?grant_type=authorization_code&code=${code}`,undefined,{
                    headers: {
                        Authorization: `Basic ${authToken}`
                    }
                })
                .then(tokenRes => {
                    console.log(tokenRes.data)
                    const token: Token = {
                        token: tokenRes.data.access_token,
                        refreshToken: tokenRes.data.refresh_token,
                        expires_in: tokenRes.data.expires_in
                    }
                    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY,JSON.stringify(token))

                    loadUserInfoByToken(token.token,successCallback)
                })
            })
            .catch(error => {
                console.log(error)
            })
        }
    })
}

export function logout(){
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
}

/**
 * 从 localStorage 中获取 token 并加载用户数据
 */
export function loadUserInfo(){
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    if(token === undefined || token === null){
        localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
        return
    }
    loadUserInfoByToken(JSON.parse(token as string).token,undefined)
}


/**
 * 根据 token 查询用户信息
 * @param token token
 */
export function loadUserInfoByToken(token: string, successCallback?: () => void){
    const authToken = Base64.encode(DEFAULT_CLIENT_ID + ":" + DEFAULT_CLIENT_SECRET)
    axiosInstance.post(SELF_TOKEN + `?token=${token}`,undefined,{
        headers: {
            Authorization: `Basic ${authToken}`
        }
    })
    .then(selfRes => {   
        if(selfRes.data === undefined || selfRes.data.user === undefined){
            return
        }
        const userInfo: UserInfo = {
            userId: selfRes.data.user.id,
            username: selfRes.data.user.username,
            userImage: selfRes.data.user.imageMin,
            userVipFlag: selfRes.data.user.vipFlag
        }


        localStorage.setItem(LOCAL_STORAGE_USER_KEY,JSON.stringify(userInfo))

        if(successCallback){
            successCallback!()
        }
    }
    )
}

/**
 * 获取用户信息
 */
export function getUserInfo(): UserInfo | undefined{
    const userInfoStr = localStorage.getItem(LOCAL_STORAGE_USER_KEY)
    const result: UserInfo = JSON.parse(userInfoStr!) as UserInfo
    return result
}