import React from "react";

import './search.css'

interface IProp{
    placeholder?: string,
    submitCallback?: Callback
}
type Callback = { bivarianceHack(name: string): void }["bivarianceHack"];
var value: string = ""

const SearchInput: React.FC<IProp> = (props) => {

    function keyUp(event:React.KeyboardEvent<HTMLInputElement>):void {
        if (event.key === "Enter" && props.submitCallback !== undefined) {
            props.submitCallback(value)
          }
    }

    return (
        <div className="search-box">
            <input placeholder={props.placeholder === undefined ? "" : props.placeholder} onKeyUp={event => keyUp(event)} onChange={v => value = v.target.value} style={{width: "100%", height: "100%",border:"none",borderRadius: "20px",outline: "none",paddingLeft: "20px", caretColor: "purple"}}/>
        </div>
    )
}
export default SearchInput