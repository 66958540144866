// import Swiper core and required modules
import {Pagination} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { isPC } from '../../utils/SystempUtils';



export interface ImageInfo{
    url: string
}

interface ImageSwiperProp{
    images: ImageInfo[]
}


const ImageSwiper: React.FC<ImageSwiperProp> = (props) => {

    function renderSingle(image: ImageInfo): JSX.Element{
        return(
            <SwiperSlide key={image.url}>
                <img src = {image.url} style={{width: isPC() ? '97%' : '100%',borderRadius: '5px', height: '250px', objectFit: 'cover'}} alt = {image.url}/>
            </SwiperSlide>
        )
    }

    return(
        <div>
            <Swiper  
                // install Swiper modules
                modules={[Pagination]}
                slidesPerView={isPC() ? 3 : 1}
                pagination={{ 
                    clickable: true,
                    el: '.swiper-pagination'
                }}
                >
                {props.images.map(item => renderSingle(item))}
                <div className="swiper-pagination" style={{marginBottom: '5px'}}/>
            </Swiper>
            
        </div>
        
    )
}

export default ImageSwiper