
const StartNode: React.FC = () => {
    return(
        <div style={{margin: "0px",padding: "0px"}}>
            <div>
                <div className="arrow-up" style={{display: 'flex'}}/>
                <div className="line" style={{height: '100px'}}/>
            </div>
        </div>
    )
}
export default StartNode