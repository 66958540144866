import { Button, Col, Input, Row } from "antd";
import React from "react";
import { login } from "./Auth";

const InputCSS: React.CSSProperties = {
    margin: '10px',
    height: '48px',
    borderColor: '#F8F8F8F8',
    borderRadius: '15px'
}

interface IState{
    username: string,
    password: string
}

interface IProp{
    success?: () => void
}



class Login extends React.Component<IProp,IState> {
    state = {
        username: '',
        password: ''
    }

    render() {
        return (
            <div style={{margin: '20px'}}>
                <Row>
                    <Col span={12}>
                        二维码
                    </Col>
                    <Col span={12}>
                        <div style={{textAlign: 'center'}}>
                            <Input placeholder="用户名" size="large" style={InputCSS} value={this.state.username} onChange={e => this.setState({username: e.target.value, password: this.state.password})}/>
                            
                            <Input.Password placeholder="密码" size="large"  style={InputCSS} value={this.state.password} onChange={e => this.setState({username: this.state.username, password: e.target.value})}/>
                            
                            <Button type="primary" style={{width: '350px',height: '45px',marginTop: '10px', marginLeft: '10px', borderRadius: '20px'}} onClick={e => login(this.state.username,this.state.password,this.props.success)}>登录</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Login