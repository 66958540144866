import { PlanMapMarker } from "../../page/planmap/PlanMap"
import { Card, Flex } from "antd";
import React, { useLayoutEffect, useState } from "react";
import {padZero, smartDateDesc } from "../../utils/DateUtils";

// Import Swiper styles
import 'swiper/css';

import './Node.css'
import { GET_WEATHER } from "../../Constants";
import { getAxiosInstance } from "../login/Auth";
import { WeatherInfo } from "./WeatherInfo";


interface IProp{
    marker: PlanMapMarker
}
const FoodNode: React.FC<IProp> = (props) => {

    const [weather, setWeather] = useState<WeatherInfo>()
    const [initFlag, setInitFlag] = useState(false)

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        queryWeather()
    })

    function queryWeather(){
        if(!props.marker.arrivalTime){
            return
        }
        const arrivalDate = new Date(props.marker.arrivalTime)
        if(arrivalDate.getTime() < new Date().getTime()){
            return
        }
        const currentDate = new Date()
        //console.info('year is ',currentDate.getFullYear(),'month is ',currentDate.getMonth() + 1,' day is ',currentDate.getDate())
        const date = new Date(`${padZero(currentDate.getFullYear())}-${padZero(currentDate.getMonth() + 1)}-${padZero(currentDate.getDate())} 24:00:00`)
        //console.info('date is ',yyyyMMddHHmmss(date))
        date.setDate(date.getDate() + 3)
        //如果到达时间超过当前时间的三天后则无法查询天气情况
        
        if(arrivalDate.getTime() <= date.getTime()){
            getAxiosInstance().get(`${GET_WEATHER}?adcode=${110101}&date=${props.marker.arrivalTime}`)
            .then(response => {
                if(response.data.success && response.data.code === 'ok' && response.data.data){
                    if(arrivalDate.getHours() > 18 && response.data.data.nighttemp && response.data.data.nightweather){
                        setWeather({
                            temp: response.data.data.nighttemp,
                            weather: response.data.data.nightweather
                        })
                    }else if(response.data.data.daytemp && response.data.data.dayweather){
                        setWeather({
                            temp: response.data.data.daytemp,
                            weather: response.data.data.dayweather
                        })
                    }
                }else{
                    console.info('query weather fail')
                }
            })
        }
        
    }

    function parseImages(json: string): string[]{
        var array = JSON.parse(json)
        var result: string[] = []
        for(var i = 0 ; i < array.length; i ++){
            result.push(array[i]["url"])
        }
        return result
    }

    return(
        <div style={{margin: "0px",padding: "0px"}}>
            <div className="node-box">
                <span className="line">
                    <span className="point"/>
                </span>

                <div className="right_box" style={{width: window.innerWidth - 70}}>
                    {/** 主体信息卡片 */}
                    <div>
                        <Card className="node-card" style={{width: window.innerWidth - 60}}>
                            <div>
                                {/**标题 */}
                                <Flex style={{height: '30px'}}>
                                    <span style={{fontWeight: 'bold'}}>{props.marker.title}</span>
                                    {
                                        weather === undefined ? <></> : <div className="weather">
                                            <div>
                                                <div className="weather-number">25°</div>
                                                <div className="weather-des">多云</div>
                                            </div>
                                        </div>
                                    }
                                </Flex>
                                {/** 图片区域 */}
                                <div style={{height: '100px',marginTop: '10px', marginBottom: '10px'}}>
                                    <div style={{overflowX: 'scroll',scrollbarWidth: 'none', whiteSpace: 'nowrap'}}>
                                        {
                                            parseImages(props.marker.jsonData).map(url => 
                                                    <img key={url} src={url} style={{width: '99px', height: '99px', display: 'inline', borderRadius: '50px', objectFit: "cover", marginRight: 5}} alt="..."/>
                                            )
                                        }
                                    </div>
                                </div>
                                {/**日期 */}
                                <div style={{float: 'right',fontSize: '12px', color: 'gray'}}>{smartDateDesc(new Date(props.marker.arrivalTime))}</div>
                            </div>
                        </Card>
                    </div>

                    {/** 备注信息卡片 */}
                    <div className="remark" style={{display: props.marker.remark === undefined || props.marker.remark === "" ? "none" : "inline-block"}}>
                        {props.marker.remark === null ? "~" : props.marker.remark}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodNode