import { Avatar } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"

import './Card.css'

interface IProp{
    id: string,
    title: string,
    iamge: string,
    width: number,
    height: number,
    viewWidth: number,
    username: string,
    userImage: string,
    onClick: (id:string) => void
}
const STANDARD_SCALE = 1.1


const MarkerCard: React.FC<IProp> = (props) =>{
    const navigateTo = useNavigate()

    /**
     * 处理图片点击事件
     * @returns 
     */
    function imageClick(): void {
        if(props.id){
            props.onClick(props.id)
        }
    }
    
    /**
     * 根据图片的长宽比例，计算图片的高度，如果比例超过 1.1 则按照 3:4 的比例显示，否则按照 1:1 的比例显示
     * @param width     图片宽度
     * @param height    图片高度
     */
    function caculateImageHeight(width: number, height: number){
        var result
        let scale = height / width
        if(scale > STANDARD_SCALE){
            result = props.viewWidth * STANDARD_SCALE
        }else{
            result = props.viewWidth * scale;
        }
        return result
    }
    
    return(
        <div className="card-box" style={{width: props.viewWidth}}>
            <img src={props.iamge} alt="加载中..." style={{width: props.viewWidth, objectFit: "cover", cursor: 'pointer', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', height: caculateImageHeight(props.width,props.height)}} onClick={e => imageClick()}/>

            <div style={{margin: '5px'}}>
                <div>{props.title}</div>

                <div style={{marginTop: '10px'}}>
                    <Avatar src = {props.userImage} size="small"/>
                    <span style={{fontSize: "10px", color: "#333333CC", fontWeight: "normal", marginLeft: '5px'}}>{props.username}</span>
                </div>
            </div>
            
        </div>
    )
}

export default MarkerCard