import React, { useLayoutEffect, useState } from "react";


import Axios from "axios";
import {HomeSearchURL } from "../../Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-responsive-masonry";
import MarkerCard from "../../components/card/MarkerCard";
import LogoNavitaion from "../../components/common/LogoNavigation";
import { useNavigate } from "react-router-dom";



var hasMord: boolean = true

interface MarkerVO{
    id: string,
    title: string,
    faceImage: string,
    imageWidth: number,
    imageHeight: number,
    userId: number,
    username: string,
    userImage: string
}

var page: number = 0
const Mobile: React.FC = () =>{
    const [initFlag, setInitFlag] = useState(false)
    const navigateTo = useNavigate()
    const [markers, setMarkers] = useState<MarkerVO[]>([])

   useLayoutEffect(() => {
    if(initFlag){
        return
    }
    setInitFlag(true)
    search(true)
   })



    /**
     * 搜索
     */
    function search(clear: boolean): void{
        if(clear === true){
            page = 0
        }
        const url: string = HomeSearchURL + "?page=" + page 
        page = page + 1
        Axios.get(url)
        .then(
            response => {
                if(response.data !== undefined && response.data.data !== undefined && response.data.data != null){
                    if(response.data.data.totalPages === page || response.data.data === undefined){
                        hasMord = false
                    }else{
                        hasMord = true
                    }
                    setMarkers(page === 0 ? response.data.data.content : markers.concat(response.data.data.content))
                }
            }
        )
    }

    return(
        <div>
            
            <div style={{height: '49px'}}></div>
            <div id="marker_list_box" style={{marginTop: '20px',overflowY: 'auto', marginLeft: '5px'}}>
                <InfiniteScroll next={() => search(false)} hasMore={hasMord} dataLength={markers.length} loader endMessage={<div style={{textAlign: "center", color: 'gray', marginTop: '30px'}}>没有啦~</div>}>
                    
                        <Masonry columnsCount={2}> 
                            {markers.map(item => <MarkerCard id={item.id} title={item.title} iamge={item.faceImage} key={item.id}
                            width={item.imageWidth} height={item.imageHeight} viewWidth={(window.innerWidth - 15) / 2} userImage={item.userImage} username={item.username} onClick={key => navigateTo(`/marker/${key}`)}/>)}
                        </Masonry>
                    
                </InfiniteScroll>
            </div>

            <LogoNavitaion />
        </div>
    )
}

export default Mobile