/**
 * 时间的简短描述，如果是同一天则只显示 **小时前，如果是同一个月只显示 xx月xx日，如果不是同一年则显示 xxxx年xx月xx日
 * @param date 
 * @returns 
 */
export function obtainDateShortDescription(dateStr: string):string{
    var date = new Date(dateStr);
    var currentDate = new Date();
    if(currentDate.getFullYear() !== date.getFullYear()){
        return date.getFullYear() + ' ' + buildNumberStr(date.getMonth()) + "-" + buildNumberStr(date.getDay());
    }
    if(currentDate.getMonth() !== date.getMonth() || currentDate.getDay() !== date.getDay()){
        return buildNumberStr(date.getMonth())  + "-" + buildNumberStr(date.getDay());
    }
    return currentDate.getHours() - date.getHours() + '小时前'
}

/**
 * 如果数值小于 10 则在前面拼接一个 0
 * @param number 
 * @returns 
 */
function buildNumberStr(number: number): string{
    if(number < 10){
        return '0' + number;
    }
    return number + '';
}

const MINUTE: number = 1000 * 60
const HOUR: number = 1000 * 60 * 60
const DAY: number = 1000 * 60 * 60 * 24


/**
 * 时间长度的简短描述
 * @param time 
 */
export function buildTimeStr(time: number | undefined): string{
    if(time === undefined){
        return ""
    }
    time = time * 1000
    if(time > DAY){
        return time / DAY + "天"
    }
    if(time > HOUR){
        return (time / HOUR).toFixed(0) + "小时"
    }
    if(time > MINUTE){
        return time / MINUTE + "分钟"
    }
    return ""
}

/**
 * 距离的描述
 * @param distance 
 */
export function buildDistanceStr(distance: number): string{
    if(distance < 100){
        return "附近"
    }
    return (distance / 1000).toFixed(0) + "公里"
}

/**
 * 智能的描述时间
 * @param date 
 */
export function smartDateDesc(date: Date): String{
    var result = "1"
    if(date === undefined || isNaN(date.getTime())){
        return result
    }
    var currentDate = new Date()
    if(date.getFullYear() !== currentDate.getFullYear()){
        result = `${date.getFullYear()}年${date.getMonth()}月`
    }else{
        result = `${date.getMonth()}月${date.getDay()}号`
    }
    let hours = date.getHours()
    let minute = date.getMinutes()
    if(hours <= 5){
        if(minute > 0){
            return `${result}凌晨${hours}点${minute}`
        }else{
            return `${result}凌晨${hours}点`
        }
    }
    if(hours <= 8){
        if(minute > 0){
            return `${result}早上${hours}点${minute}`
        }else{
            return `${result}早上${hours}点`
        }
    }
    if(hours <= 11){
        if(minute > 0){
            return `${result}上午${hours}点${minute}`
        }else{
            return `${result}上午${hours}点`
        }
    }
    if(hours <= 13){
        if(minute > 0){
            return `${result}中午${hours <= 12 ? hours : hours - 12}点${minute}`
        }else{
            return `${result}中午${hours <= 12 ? hours : hours - 12}点`
        }
    }
    if(hours <= 17){
        if(minute > 0){
            return `${result}下午${hours - 12}点${minute}`
        }else{
            return `${result}下午${hours - 12}点`
        }
    }
    if(hours <= 24){
        if(minute > 0){
            return `${result}晚上${hours - 12}点${minute}`
        }else{
            return `${result}晚上${hours - 12}点`
        }
    }
    return result
}

export function padZero(num: number) {
    return num < 10 ? '0' + num : num;
  }

export function yyyyMMddHHmmss(date: Date) {
    var year = date.getFullYear();
    var month = padZero(date.getMonth() + 1); // getMonth() 返回的月份是从 0 开始的
    var day = padZero(date.getDate());
    var hours = padZero(date.getHours());
    var minutes = padZero(date.getMinutes());
    var seconds = padZero(date.getSeconds());
   
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function yyyyMMdd(date: Date) {
    var year = date.getFullYear();
    var month = padZero(date.getMonth() + 1); // getMonth() 返回的月份是从 0 开始的
    var day = padZero(date.getDate());
    return `${year}-${month}-${day}`;
}