import { Avatar, Tabs } from "antd";
import Axios from "axios";
import { Component, useLayoutEffect, useState } from "react";
import { MarkerDetailURL } from "../../Constants";
import { obtainDateShortDescription } from "../../utils/DateUtils";
import ImageSwiper, { ImageInfo } from "../../components/support/ImageSwiper";
import ImageCardSwiper from "../../components/support/ImageCardSwiper";
import ImageCoverflowSwiper from "../../components/support/ImageCoverflowSwiper";
import { useNavigate, useParams } from "react-router-dom";
import { isPC } from "../../utils/SystempUtils";
import PC, { NavType } from "../home/pc/PC";


interface MarkerModel{
    id?: number,
    title?: string,
    description?: string,
    username?: string,
    userImage?: string,
    jsonData?: string,
    cameraPosition: CameraPositionModel[],
    foodPosition: FoodPositionModel[],
    stayLocation: StayPositionModel[],
    creationDate: string
}

interface CameraPositionModel{
    id: number,
    description: string,
    jsonData: string
}

interface FoodPositionModel{
    id: number,
    description: string,
    jsonData: string
}

interface StayPositionModel{
    id: number,
    description: string,
    jsonData: string
}

function buildCameraPositionModel(array: any): CameraPositionModel[]{
    if(array === undefined || array === '' || array === null){
        return []
    }
    var result: CameraPositionModel[] = []
    for(var i = 0 ; i < array.length; i ++){
        var item = array[i]
        result.push({id: item["id"] as number,description: item["description"], jsonData: item["jsonData"]})
    }
    return result
}

function buildFoodPositionModel(array: any): FoodPositionModel[]{
    if(array === undefined || array === '' || array === null){
        return []
    }
    var result: FoodPositionModel[] = []
    for(var i = 0 ; i < array.length; i ++){
        var item = array[i]
        result.push({id: item["id"] as number,description: item["description"], jsonData: item["jsonData"]})
    }
    return result
}

function buildStayPositionModel(array: any): StayPositionModel[]{
    if(array === undefined || array === '' || array === null){
        return []
    }
    var result: StayPositionModel[] = []
    for(var i = 0 ; i < array.length; i ++){
        var item = array[i]
        result.push({id: item["id"] as number,description: item["description"], jsonData: item["jsonData"]})
    }
    return result
}


/**
 * 构建 ImageInfo 
 */
function buildImageSwiperProp(jsonData: string): ImageInfo[]{
    if(jsonData === null || jsonData === ''){
        return []
    }
    var result: ImageInfo[] = []
    var array = JSON.parse(jsonData)
    for(var i = 0 ; i < array.length; i ++){
        result.push({url: array[i]["url"]})
    }
    return result
}

/**
 * https://formidable.com/open-source/nuka-carousel/installation
 */
const Marker: React.FC = () => {
    const [marker,setMarker] = useState<MarkerModel>()
    const {id} = useParams()
    const navigateTo = useNavigate()

    useLayoutEffect(() => {
        if(marker !== undefined){
            return
        }
        if(isPC()){
            navigateTo(`/home/find?id=${id}`)
            return
        }
        Axios.get(MarkerDetailURL + '?id=' + id)
        .then(
            response => {
                if(response.data.success){
                    let data = response.data.data
                    setMarker({
                        id: data.id,
                        title: data.title,
                        description: data.description,
                        username: data.username,
                        userImage: data.userImage,
                        jsonData: data.jsonData,
                        cameraPosition: buildCameraPositionModel(data.cameraPositionDTOS),
                        foodPosition: buildFoodPositionModel(data.deliciousFoodPositionDTOS),
                        stayLocation: buildStayPositionModel(data.stayPositionDTO),
                        creationDate: data.creationDate
                    })
                }else{
                    console.info(response.data.message)
                }
            }
        )
    })

    

    //图文
    function main(): JSX.Element{
        return(
            <div>
                {/** 图片 */}
                <div> 
                    <ImageSwiper images={buildImageSwiperProp(marker?.jsonData ?? "")}/> 
                </div> 

                <div style={{margin: 10}}>
                    {/** 标题*/}
                    <div>
                        <h3>
                            {marker?.title}  
                        </h3>
                    </div>  
                    {/** 描述 */}
                    <div>
                        <p>
                            {marker?.description}
                        </p>
                    </div>
                    {/** 时间 */}
                    {marker?.creationDate === undefined ? <></> : <div style={{fontSize:12,color: 'grey',width: '100%',display: 'flex', justifyContent: 'right'}}>发布于：{obtainDateShortDescription(marker?.creationDate)}</div>}
                    
                </div>
            </div>
        )
    }

    //机位
    function camera(): JSX.Element{
        return(
            <div>
                {marker?.cameraPosition.map(item => cameraSingle(item))}
            </div>
        )
    }

    function cameraSingle(model: CameraPositionModel): JSX.Element{
        return(
            <div key={model.id}>
                <div>{model.description}</div>
                <ImageCardSwiper images={buildImageSwiperProp(model.jsonData)}/>
            </div>
        )
    }

    //美食
    function food(): JSX.Element{
        return(
            <div>
                {marker?.foodPosition.map(item => foodSingle(item))}
            </div>
        )
    }

    function foodSingle(model: FoodPositionModel): JSX.Element{
        return(
            <div key={model.id}>
                <div style={{fontWeight: 'bold'}}>{model.description}</div>
                <ImageCoverflowSwiper images={buildImageSwiperProp(model.jsonData)}/>
            </div>
        )
    }

    //住宿
    function stay(): JSX.Element{
        return(
            <div>
                {marker?.stayLocation.map(item => staySingle(item))}
            </div>
        )
    }

    function staySingle(model: StayPositionModel): JSX.Element{
        return(
            <div key={model.id}>
                <div style={{fontWeight: 'bold'}}>{model.description}</div>
                <ImageCoverflowSwiper images={buildImageSwiperProp(model.jsonData)}/>
            </div>
        )
    }

    function mobile(): JSX.Element{
        return(
            <div> 
                {/** 作者信息 */}
                <div>
                    <Avatar src = {marker?.userImage} size="large" style={{margin: 10}}/>
                    <span>{marker?.username}</span>
                </div>
                
    
                <Tabs
                    defaultActiveKey="0"
                    centered
                    items={[
                        {
                            label: '图文',
                            key: "0",
                            children: main()
                        },
                        {
                            label: '机位点',
                            key: "1",
                            children: camera()
                        },
                        {
                            label: '美食',
                            key: '2',
                            children: food()
                        },
                        {
                            label: '住宿',
                            key: '3',
                            children: stay()
                        }
                    ]}
                />
            </div>
        )
    }

    return(
        <div>
            { mobile()}
        </div>
    )
}


export default Marker