import React, { useState } from "react"


interface IProp{
    name: string,
    selectFlag: boolean,
    onClick?: ClickListener | undefined;
}
type ClickListener = { bivarianceHack(name: string): void }["bivarianceHack"];

const Pointer: React.FC<IProp> = (props) => {
    const [enterFlag,setEnterFlag] = useState(false)


    function handClick(name: string){
        if(props.onClick !== undefined){
            props.onClick(name)
        }
    }

    return (
        <span style={{borderRadius: '5px',color: 'gray' ,marginRight: '5px',backgroundColor: enterFlag || props.selectFlag ? "#F8F8F8F8" : "white", padding: '10px', fontSize: '10px', cursor: 'pointer'}} onMouseEnter={() => setEnterFlag(true)} onMouseLeave={() => setEnterFlag(false)} onClick={e => handClick(props.name)}>
            {props.name}
        </span>
    )
}

export default Pointer