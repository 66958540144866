import { Avatar, Button, Flex, Modal } from "antd";
import React, { useLayoutEffect, useState } from "react";
import LogoICON from "../../resource/logo.png"
import Navigation from "../../../components/navigation/Navigation";


import Login from "../../../components/login/Login";
import MarkerList from "../MarkerList";
import Selected from "../selected/Selected";
import { getUserInfo, loadUserInfo } from "../../../components/login/Auth";
import Personal from "../Personal";

import './PC.css'
import Layout, { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { Outlet, useNavigate } from "react-router-dom";

interface IProp{
    nav?: NavType
}


export enum NavType{
    Main,Selected,Personal
}

const siderStyle: React.CSSProperties = {
    backgroundColor: 'white',
    height: window.innerHeight,
    paddingTop: '10px'
};

const contentStyle: React.CSSProperties = {
    padding: '10px'
}

const PC: React.FC<IProp> = (props) => {

    const [initFlag, setInitFlag] = useState(false)
    const [showModal, setShowModal] = useState(false)
    
    const [nav, setNav] = useState<NavType>(NavType.Main)
    const navigateTo = useNavigate()
    
    

    /**
     * 显示登录弹窗
     */
    function showLoginModal(){
        setShowModal(true)
    }

    /**
     * 关闭登录弹窗
     */
    function closeLoginModal(){
        setShowModal(false)
    }


    function changeType(type: NavType){
        setNav(type)
        if(type === NavType.Main){
            navigateTo('/home/find')
        }else if(type === NavType.Selected){
            navigateTo('/home/selected')
        }
    }

    function initChangeType(){
        const href = window.location.href 
        if(href.indexOf('selected') != -1){
            setNav(NavType.Selected)
        }
    }

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        initChangeType()
        if (props.nav){
            setNav(props.nav)
        }
        loadUserInfo()
    })


    return(
        <div style={{overflow: 'hidden',height: window.innerHeight}}>
            <Modal open={showModal} width={800} footer={false} maskClosable={true} onCancel={e => closeLoginModal()}>
                <Login success={() => closeLoginModal}/>
            </Modal>
            
            
            <Flex>
                <Layout>
                    <Sider style={siderStyle}>
                        <Flex style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {/** <img src={LogoICON} style={{width: '60px', height: '60px'}} alt="Logo"/>  */}
                            <p style={{fontSize: '30px', fontWeight: 'bold', color: 'orange'}}>山海令</p>
                        </Flex>
                        <Navigation name="发现" selectFlag={nav === NavType.Main} onClick={e => changeType(NavType.Main)}/>

                        <Navigation name="精选" selectFlag={nav === NavType.Selected} onClick={e => changeType(NavType.Selected)}/>

                        <Navigation name="计划地图" path="/plan"/>
                        
                        <Navigation name="关于我们" path=""/>
                        
                        <Navigation name="隐私协议" path=""/>

                        <Navigation name="下载 APP" path=""/>

                        {
                            getUserInfo() === undefined || getUserInfo() === null? 
                            <Button type="primary" size="large" shape="round"  style={{width: '80%',marginLeft: '10px'}} onClick={e => {showLoginModal()}}>登录</Button>
                            :
                            <Navigation name = {
                                <div style={{cursor: 'pointer',}}>
                                <Avatar src = {getUserInfo()?.userImage} size="default"/>
                                <span style={{marginLeft: '10px',alignContent: "center"}}>{getUserInfo()?.username}</span>
                            </div>
                            } selectFlag={nav === NavType.Personal} onClick={e => changeType(NavType.Personal)}/>
                        }
                        
                        <div className="company-box">
                            社区分享旅途中的精彩瞬间
                            <br/>
                            快速指定专属的出行攻略
                            <br/>
                            点赞、收藏一起发掘每一个好地方
                            <br/>
                            您的家乡有几个地方上榜
                        </div>

                        <div style={{position: 'absolute', bottom: 0,fontSize: '12px',fontWeight: 400, marginBottom: '30px',fontFamily: 'PingFang SC,DFPKingGothicGB-Regular,sans-serif', marginRight: "30px", marginTop: "30px", color: '#33333399', paddingLeft: '10px'}}>
                            <br/>
                            深圳市表达式科技有限责任公司
                            <br/>
                            <br/>
                            深圳市罗湖区南湖街道渔邨社区嘉宾路4055号城市天地广场东座7层701
                        </div>
                    </Sider>
                    <Layout>
                        <Content style={contentStyle}>

                            <Outlet />
                            {/**
                                <div id="content_box" style={{height: '100%',width: '90%'}} >
                                    nav === NavType.Main ? <MarkerList/> : nav === NavType.Selected ? <Selected /> : nav === NavType.Personal ? <Personal /> : <></> 
                                </div>
                            */}
                        </Content>
                    </Layout>
                </Layout>
            </Flex>
        </div>
    )
}

export default PC