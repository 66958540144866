/**
     * 检测是否是 PC
     */
export function isPC(): boolean{
    if (
        navigator.userAgent.match('/Android/i') ||
        navigator.userAgent.match('/webOS/i') ||
        navigator.userAgent.match('/iPhone/i') ||
        navigator.userAgent.match('/iPad/i') ||
        navigator.userAgent.match('/iPod/i') ||
        navigator.userAgent.match('/BlackBerry/i') ||
        navigator.userAgent.match('/Windows Phone/i')||
        navigator.userAgent.includes('Mobile')
    ) {
        // 移动端适配
       return false
    } else {
        return true
    }
}