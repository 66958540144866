import React, { ReactNode } from "react"
import LogoICON from "../../resource/logo.png"
import {Button, Col, Row, Space } from "antd";
import { GET_VERSION_INFO } from "../../Constants";

import Axios from "axios";

const isAndroid=()=> {
 
    const u = navigator.userAgent;

    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {

      return true;

    }

    return false;
}

class LogoNavitaion extends React.Component<{},{}>{


    /**
     * 下载客户端
     */
    download(){
        Axios.get(GET_VERSION_INFO)
        .then(response => {
            if(response.status === 200){
                if(response.data.data !== undefined && response.data.data !== null){
                    var url: string = ""
                    if(isAndroid() && response.data.data.androidApkUrl !== undefined){
                        url = response.data.data.androidApkUrl
                    }else{

                    }
                    if(url !== undefined && url !== ''){
                        Axios.get(url,{
                            responseType: 'blob',
                            headers: {
                                "Access-Control-Allow-Origin":"*"
                            }
                        })
                        .then(res => {
                            if(res.status === 200 && res.data){
                                const {data, headers} = res
                                let fileName = 'shl.apk'
                                // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                                const blob = new Blob([data], { type: headers['content-type'] })
                                const dom = document.createElement('a')
                                const downUrl = window.URL.createObjectURL(blob)
                                dom.href = downUrl
                                dom.download = decodeURIComponent(fileName)
                                dom.style.display = 'none'
                                document.body.appendChild(dom)
                                dom.click()
                                dom.parentNode!.removeChild(dom)
                                window.URL.revokeObjectURL(url)
                            }
                        })
                    }
                }else{
                    alert('抱歉目前还没有发布新版本')
                }
            }
        })
    }

    render(): ReactNode {
        return(
            <div style={{position: 'fixed',backgroundColor: 'white',width: '100%',top: '0px',padding: '10px', zIndex: 1000, borderBottom: '1px solid rgba(224, 224, 224, 1)'}}>
                    <Row>
                        <Col span={3}>
                            <img src={LogoICON} style={{width: '49px', height: '49px'}} alt="Logo"/>
                        </Col>
                        <Col span={16} style={{marginLeft: 5}}>
                            <div style={{height: '100%', verticalAlign: "center",marginLeft: '10px'}}>
                                <Row>
                                    <Col span={24}>
                                        <div style={{fontSize: '16px',fontWeight: 'bold',marginTop: '5px'}}>
                                            山海令 APP
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row style={{marginTop: '8px'}}>
                                    <Col span={24}>
                                        <div style={{fontSize: '12px',color: 'gray'}}>
                                            山海不见你，我便带着山海向你走来
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {
                            /**
                             * <Col span={5}>
                            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <Button style={{backgroundColor: '#8C50FF',color:'white'}} onClick={e => this.download()}>下载</Button>
                            </div>
                        </Col>
                             */
                        }
                        
                    </Row>
                </div>
        )
    }
}

export default LogoNavitaion