//查询计划点详情
export const MarkerDetailURL: string = "/platform/v1/marker/id"

//查询计划地图的公开接口
export const PlanMapPublicURL: string = "/platform/v1/plan_map/id"

//查询城市列表的公开接口
export const CityListURL: string = "/platform/v1/marker/city"

//首页的搜索公开接口
export const HomeSearchURL: string = "/search/v1/search/marker/search"

//精选列表的公开接口
export const BeautyMarkerURL: string = "/platform/v1/marker/beauty"

//查询发布版本的公开接口
export const GET_VERSION_INFO: string = "/manage/v1/version_management"

//查询我自己的计划点
export const GET_MINE_MARKER: string = "/platform/v1/marker"

//查询我点赞的计划点
export const GET_LIKE_MARKER: string = "/platform/v1/marker/like"

//查看我收藏的计划点
export const GET_COLLECT_MARKER: string = "/platform/v1/marker/collect"

//查询我的计划地图
export const GET_MINE_PLAN_MAP: string = "/platform/v1/plan_map"

//查询天气情况 adcode、date
export const GET_WEATHER: string = "/platform/v1/weather"

//查询安全询问的留言板
export const GET_MESSAGE_BOARD: string = "/event/v1/board"

//查看行动轨迹
export const GET_TRACK_RECORD: string = "/event/v1/track"