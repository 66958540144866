import { Button, Flex, GetProp, Input, message } from "antd"
import { OTPProps } from "antd/es/input/OTP"
import { useLayoutEffect, useState } from "react"
import { useParams } from "react-router-dom"

import BG from '../../resource/planmap_pwd_bg.jpg'
import BG_PC from '../../resource/planmap_pwd_bg_pc.jpg'

import { isPC } from "../../utils/SystempUtils"
import { GET_MESSAGE_BOARD } from "../../Constants"
import Axios from "axios"
import ErrorPage from "../../support/ErrorPage"

import './messageboard.css'
import { Link } from "react-router-dom"

interface MessageBoardModel{
    message: string,
    planMapPassword: string,
    planMapId: number
}

const MessageBoard: React.FC = () => {
    const [initFlag, setInitFlag] = useState(false)
    const {id} = useParams()
    const [error, setError] = useState(false)
    const [passwordFlag, setPasswordFlag] = useState(false)
    const [password, setPassword] = useState<String | undefined>()
    const [btnDisableFlag, setBtnDisableFlag] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();

    useLayoutEffect(() => {
        if(initFlag){
            return
        }
        setInitFlag(true)
        loadData()
    })

    function loadData(){
        setBtnDisableFlag(true)
        Axios.get(password !== undefined ? `${GET_MESSAGE_BOARD}?id=${id}&password=${password}` : `${GET_MESSAGE_BOARD}?planMapId=${id}`)
        .then(
            response => {
                if(password?.length === 5){
                    setBtnDisableFlag(false)
                }
                if(response.data && response.data.success && response.data.code == 'ok'){
                    let data = response.data.data
                    setPasswordFlag(false)
                    
                }else if(response.data.message === '请输入查看密码' || response.data.message === '查看密码错误'){
                    if(response.data.message === '查看密码错误'){
                        messageApi.open({
                            type: 'error',
                            content: '密码不正确！',
                          });
                    }
                    setPasswordFlag(true)
                }else{
                    setError(true)
                }
            }
        )
    }

    const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
        setPassword(text)
        setBtnDisableFlag(false)
      };
    
      const sharedProps: OTPProps = {
        onChange,
      };

    /**
     * 输入密码页面
     * @returns 
     */
    function renderPassword(): JSX.Element{
        return(
            <div className="password-box" style={{height: window.innerHeight, backgroundImage: isPC() ? `url(${BG_PC})` : `url(${BG})`}}>
                
                <div className="password-card">
                    <h1>Password</h1>
                    <Flex gap="middle" align="center" justify="center" vertical>
                        <Input.OTP length={5} size="large" formatter={(str) => str.toUpperCase()} {...sharedProps} />
                        <p>请查看通知短信或邮件</p>
                        <Button type="primary" disabled = {btnDisableFlag} onClick={() => loadData()}>确认</Button>
                    </Flex>
                </div>
            </div>
        )
    }

    function renderError(): JSX.Element{
        return(
            <ErrorPage/>
        )
    }

    function renderMain(): JSX.Element{
        return(
            <div className="box" style={{height: window.innerHeight, width: '100%'}}>
                <h1 className="title">留言</h1>
                <p className="remark" style={{height: window.innerHeight * 0.5}}>
                    明天打算去呼伦贝尔大草原，然后晚上到XXX酒店落脚
                </p>

                <div style={{marginTop: '20px'}}>
                    <Link to={`/plan_map/${id}`}>查看行程计划</Link>

                    <Link to={`/track/${id}/${password}`} style={{position: 'absolute', right: '0px'}}>查看行动轨迹</Link>
                </div>
            </div>
        )
    }

    return(
        <div >
            {contextHolder}
            {
                error ? renderError() : passwordFlag ? renderPassword() : renderMain()
            }
        </div>
    )
}

export default MessageBoard 