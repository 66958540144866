import React, { ReactNode, useState } from "react"


interface IProp{
    path?: string,
    selectFlag?: boolean,
    name: string | ReactNode,
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}
const Navigation: React.FC<IProp> = (props) => {
    const [enterFlag,setEnterFlag] = useState(false)

    return (
        <div style={{borderRadius: '15px',backgroundColor: enterFlag || props.selectFlag? "#F8F8F8F8" : "white", padding: '10px', fontSize: '15px', cursor: 'pointer', margin: "10px", marginRight: "30px"}} onMouseEnter={() => setEnterFlag(true)} onMouseLeave={() => setEnterFlag(false)} onClick={props.onClick}>
            {props.name}
        </div>
    )
}

export default Navigation