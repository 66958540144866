// import Swiper core and required modules
import {EffectCards} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { ImageInfo } from './ImageSwiper';


interface ImageSwiperProp{
    images: ImageInfo[]
}


const ImageCardSwiper: React.FC<ImageSwiperProp> = (props) => {

    function renderSingle(image: ImageInfo): JSX.Element{
        return(
            <SwiperSlide key={image.url}>
                <img src = {image.url} style={{width: '300px',height: '300px',borderRadius: '5px',objectFit: 'cover'}} alt = {image.url}/>
            </SwiperSlide>
        )
    }

    return(
        <div >
            <Swiper  
                // install Swiper modules
                modules={[EffectCards]}
                slidesPerView={1}
                effect='cards'
                cardsEffect={{
                    perSlideRotate: 5,
                    perSlideOffset: 8,
                    slideShadows: false
                }}
                >
                {props.images.map(item => renderSingle(item))}
            </Swiper>
            
        </div>
        
    )
}

export default ImageCardSwiper