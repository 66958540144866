// import Swiper core and required modules
import {EffectCoverflow} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { ImageInfo } from './ImageSwiper';
import { isPC } from '../../utils/SystempUtils';


interface ImageSwiperProp{
    images: ImageInfo[]
}


const ImageCoverflowSwiper: React.FC<ImageSwiperProp> = (props) => {

    function renderSingle(image: ImageInfo): JSX.Element{
        return(
            <SwiperSlide key={image.url}>
                <img src = {image.url} style={{width: '200px',height: '200px',borderRadius: '5px',objectFit: 'cover'}} alt = {image.url}/>
            </SwiperSlide>
        )
    }

    return(
        <div >
            <Swiper  
                // install Swiper modules
                modules={[EffectCoverflow]}
                slidesPerView={isPC() ? 5 : 2}
                effect='coverflow'
                centeredSlides = {true} 
                coverflowEffect={{
                    rotate: 30,
                    stretch: 10,
                    depth: 60,
                    modifier: 2,
                    slideShadows : false
                }}
                >
                {props.images.map(item => renderSingle(item))}
            </Swiper>
            
        </div>
        
    )
}

export default ImageCoverflowSwiper